<template>

  <div class="content">


    <div class="slide1">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide :key="index" class="swiperSlide" v-for="(item,index) in swiperList">
          <a :href="item.link" target="_blank"><img :src="$url+item.image" alt=""></a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>


    <img class="company" src="../assets/dong/zhuanjia.png" style="width: 260px; height: 55px;">

    <!--    <img class="team" src="../assets/img/team.png">-->
    <div class="demo-image">
      <div class="block" v-for="fit in team" :key="fit">
        <el-image

            :src="fit.image"
            fit="cover"></el-image>
        <span class="demonstration">{{ fit.username }}</span>
        <span class="demonstration1" style="line-height: 25px;" v-html="fit.content"></span>
      </div>
    </div>


  </div>

</template>

<script>
export default {

  name: "advantage",
  data() {
    return {
      report: [],
      team: [],
      swiperList: [],
      swiperOption: {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许点击小圆点跳转
        },
        autoplay: {
          delay: 5000,
        },

      },
      tage: [],
      bming: [],
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
    }
  },
  methods: {
    //优势
    getBanner() {
      this.$get('index/banner')
          .then(res => {
            this.swiperList = res.data
          })
    },
    getdavantage() {
      this.$get('index/advantage')
          .then(res => {
            const data = []
            for (let i in res.data) {
              data.push(res.data[i].image)
              data.push(
                  {
                    image: res.data[i].icon_image,
                    title: res.data[i].title,
                    desc: res.data[i].desc.split(',')
                  }
              )
            }
            this.bming = data

          })
    },
    //优秀团队
    getteam() {
      this.$get('index/team')
          .then(res => {
            console.log("data", res.data)
            this.team = res.data
          })
    },
    //新闻接口
    getnews() {
      this.$get('index/news?type=2&limit=4')
          .then(res => {
            this.report = res.data.data
          })
    },


  },
  created() {
    this.getBanner()
    this.getteam()
    this.getnews()
    this.getdavantage()
  }
}
</script>

<style lang="less" scoped>

// @media (max-width: 1920px) {
//   .block {
//     float: left;
//     height: 592px;
//     width: 400px;
//     margin-left: 15px;

//   }
//   .el-image {
//     width: 400px;
//     height: 460px;
//     position: relative;
//     display: inline-block;
//     overflow: hidden;

//   }
//   .demonstration1 {
//     width: 400px;
//     font-size:12px;
//     line-height:20px;
//   }



// }

// @media (max-width: 1549px) {
//   .block {
//     float: left;
//     height: 442px;
//     width: 280px;
//     margin-left: 20px;
//   }

//   .demonstration1 p {
//     width: 200px;
//   }

//   .el-image {
//     width: 280px;
//     height: 280px;
//   }
// }

// @media (max-width: 1280px) {
//   .block {
//     float: left;
//     height: 492px;
//     width: 260px;
//     margin-left: 20px;
//   }

//   .el-image {
//     width: 260px;
//     height: 280px;
//   }
// }

//
//@media (max-width:2000px){
//  .demo-image{
//    display: block;
//    width:75%;
//    margin:0 auto;
//    margin-bottom:100px;
//  }
//  .block{
//    padding: 80px 0 0 0;
//    text-align: center;
//    //border-right: 1px solid #eff2f6;
//    display: inline-block;
//    //width: 33%;
//    margin-left:40px;
//    box-sizing: border-box;
//    vertical-align: top;
//  }
//  .el-image{
//    width:423px;
//    height:412px;
//  }
//}

  .block {
    float: left;
    height: 33vw;
    width: 22vw;
    margin-left: 1vw;
  }

  .demonstration1 p {
    width: 20vw;
    line-height: 25px;
  }

  .el-image {
    width: 22vw;
    height: 24vw;
  }



.slide1 {
  width: 100%;
  height: 31.2500vw;
  background: #e8e7e7;

  .swiper, .swiperSlide, .swiperSlide img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .swiper-pagination-bullet-active {
  background: #fff;
}

.demo-image {
  display: block;
  width: 70vw;
  margin: 0 auto;
  margin-bottom: 100px;
}

.campus {
  opacity: 0;
}

.campus-active {
  opacity: 1;
}

//.el-image {
//  position: relative;
//  display: inline-block;
//  overflow: hidden;
//  width:230px;
//  height:230px;
//}
.demonstration {
  display: block;
  line-height: 20px;
  text-align: left;
  color: #000;
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  width: 20vw;
}
.demonstration1 p{
  font-size:12px;
  line-height:10px;
}

.content {
  max-width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
}

.adv {
  height: 7.8125vw;
  width: 100%;
  margin: auto;
}

.tage {
  /*height:50.6250vw;*/
  background: white;
  /*background-image: url("../assets/images/wbanner.png");*/
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*overflow: hidden;*/
}

.vant {
  height: auto;
  background-image: url("../assets/images/wbanner.png");
  background-repeat: no-repeat;
  background-size: cover;

  .allwant {
    width: 70.0521vw;
    height: 32.4479vw;
    padding: 3.4896vw 0;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .item {
      width: 14.0104vw;
      height: 9.3750vw;

      .da {
        width: 100%;
        height: 100%;
      }

      .xiao {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 2.6563vw;
          height: 2.6563vw;
          border-radius: 50%;
          transition: all 1s;
        }

        img:hover {
          transform: scale(1.2) rotate(360deg);
        }

        span {
          font-size: 1.0417vw;
          font-weight: bold;
          color: #333333;
          line-height: 1.6667vw;
        }

        p {
          font-size: 0.7292vw;
          font-weight: 550;
          color: #666666;
          line-height: 1.4583vw;
        }

      }
    }
  }

}


.now {
  font-size: 0.7292vw;
  font-weight: 550;
  color: #666666;
  line-height: 1.7188vw;
}

.major {
  /*height: 11.4063vw;*/
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 18.75vw;
  width: calc(100% - 18.75vw - 18.75vw);
  position: relative;

}


.major .items {
  display: flex;
  flex-direction: row;
  height: 5.2083vw;
  width: 50%;
  overflow: hidden;
  margin-bottom: 0.9896vw;
  cursor: pointer;

}

.left {
  padding-right: 3.6458vw;
}

.right {
  padding-left: 3.6458vw;
}

.major .items img {
  width: 9.3750vw;
  height: 5.2083vw;
  margin-right: 0.9896vw;
}

.word1 {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5.2083vw;
}

.major .items h1 {
  flex: 1;
  width: 15.6250vw;
  font-size: 1.0417vw;
  line-height: 1.1vw;
  margin-bottom: 0.7292vw;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #333333;
}

.major .items a {
  text-align: left;
  color: #666666;
  font-size: 0.7292vw;
  border-bottom: 0.0521vw solid #666666;


}

.team {
  height: 7.8125vw;
  width: 100%;
  margin: auto;
  margin-top: 4.4792vw;
}

.goodteam {
  width: 100%;
  background: white;
  /*height: 46.9271vw;*/
  padding-left: 18.7500vw;
  padding-right: 18.7500vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /*overflow: hidden;*/
  padding-bottom: 3vw;

}

.demonstration {
  word-break: break-all;
}

.teamlist {
  display: flex;
  flex-direction: row;
  margin-top: 3.5937vw;
  margin-bottom: 0;
  height: 10.7396vw;
  overflow-y: hidden;
  position: relative;
  transition: height 1s;
}

.words {
  margin-left: 5.5729vw;
  margin-top: 0.1042vw;

}

.company {
  //height: 6.1979vw;
  //width: 100%;
  //margin-top: 4.1146vw;
  height: 8vw;
  display: block;
  margin: 0 auto;
  margin-top: 1.8792vw;
  margin-bottom: 1.4792vw;
}

.words span {
  margin-right: 1.0417vw;
  font-size: 1vw;
  font-weight: 550;
  color: #333333;
}

.words p {
  min-height: 5.8333vw;
  font-size: 0.8333vw;
  color: #666666;
  margin-top: 0.8229vw;
  text-align: left;
}

.words p /deep/ span {
  font-size: 0.8333vw !important;
  font-weight: 500;
  color: #666666 !important;
  line-height: 1.6667vw;
}

.teamimg {
  width: 8.5417vw;
  height: 9.7396vw;
}

.line {
  width: 0.0521vw;
  height: 13.5863vw;
  background: #E6E6E6;
  position: absolute;
  left: 50%;
}

.more {
  width: 3.8vw;
  font-size: 0.7292vw;
  font-weight: 550;
  color: #666666;
  line-height: 1.6667vw;
  cursor: pointer;
  border-bottom: 0.0521vw solid #666666;
}

.showButton {
  position: absolute;
  bottom: 10px;
  right: 0;
}


</style>
